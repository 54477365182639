* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $mukta;
  background-color: $xlight;
}

a {
  color: inherit;
  text-decoration: none !important;
}

ul,
li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

.table {
  border-collapse: collapse;
  outline: none;
  table-layout: fixed;

  td {
    border-top: none;
    // padding: 0 3px !important;
    // height: 60px;
    vertical-align: middle;
    word-wrap: break-word;
    text-align: left;
    box-sizing: border-box;
  }

  th {
    border-top: none;
    padding: 0 3px !important;
    // height: 60px;
    vertical-align: middle;
    background-color: #fafafa;
    word-wrap: break-word;
    text-align: left;
    box-sizing: border-box;
  }
}

// New
.text-red {
  color: red;
}
.single-line {
  float: left;
  width: 700px;
}

.inp-container {
  float: left;
  width: auto;
  margin-left: 10px;
}

.inp-container span {
  clear: both;
}

.Hospital {
  width: 510px !important;
}

.ui-corner-bottom {
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.inner-wrapper {
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  clear: both;
  padding-bottom: 5px;
}

.household-block {
  padding: 10px 12px 0;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > tbody > tr > td {
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="tel"] {
  background: #fff;
  border: 1px solid #ccc;
  // width: 164px;
  font: 12px "Roboto-Regular";
  color: #333;
  padding: 3px 4px;
}

input[type="email"] {
  background: #fff;
  border: 1px solid #ccc;
  // width: 164px;
  float: left;
  font: 12px "Roboto-Regular";
  color: #333;
  padding: 3px 4px;
}

input[type="text"].code-no {
  width: 39px;
}

input[type="text"].year {
  width: 62px !important;
}

input[type="text"].month {
  width: 44px !important;
  margin-left: 7px;
}

input[type="text"].month,
input[type="text"].day {
  width: 44px !important;
  margin-left: 7px;
}

.input-calendar {
  float: left;
}

select {
  font-family: "Roboto-Regular";
  color: #333;
  width: 120px;
  margin-left: 6px;
  height: 22px;
  float: left;
}

.select-pop {
  float: left;
  margin: 3px 0 0 3px;
}

.button-block {
  text-align: center;
  clear: both;
}

.member-block {
  margin-bottom: 30px;
}

.member-registration-block {
  overflow: hidden;
  margin: 10px 0 9px;
}

.member-registration-block .title {
  border-bottom: 1px solid #333;
  padding-bottom: 3px;
  margin-bottom: 0px;
  overflow: hidden;

  &.noBorder {
    border: none;
  }
}

.member-registration-block h2 {
  color: #333;
  font-family: "Roboto-Bold";
  font-size: 18px;
  float: left;
  line-height: 1.4;
}

.member-registration-block ul {
  overflow: hidden;
}

.member-registration-block ul li {
  width: 250px;
  float: left;
  margin: 0 6px 0 0;
  padding-bottom: 5px;
}

.member-registration-left-block {
  float: left;
  width: 950px;
}

.member-registration-right-block {
  float: right;
  width: 154px;
  margin-top: -241px;
}

.member-registration-block label {
  width: 100%;
  padding: 4px 0 6px;
  margin: 0;
}

.member-registration-block input[type="text"] {
  width: 150px;
  clear: both;
}

.member-registration-block input[type="text"].code-no {
  width: 37px;
  text-align: center;
  padding: 3px 0;
}

.member-registration-block select {
  width: fit-content;
}

.member-registration-block .input-calendar.first {
  clear: left;
}

.same-checkbox {
  font-size: 14px;
  color: #0287b4;
  float: right;
}

.same-checkbox input {
  vertical-align: middle;
  margin: -2px 4px 0 0;
}

.field-validation-valid {
  display: none;
}

.textbox_req {
  background: #ffffff url(https://public.donidcr.gov.np/Content/images/req.gif)
    no-repeat right center !important;
}

.vital-add-left-block {
  width: 600px;
}

.vital-add-baby-block li {
  list-style: none;
}

.vital-add-baby-block .vital-add-info input[type="text"] {
  float: left;
  clear: none;
  width: 190px;
  margin-right: 8px;
  margin-left: 12px;
}

.vital-add-baby-block .input-calendar.first {
  clear: none;
}

.vital-add-baby-block li {
  margin-bottom: 10px;
  overflow: hidden;
}

.vbutton-block {
  margin-bottom: 20px;
}

.vital-birth-block {
  margin: 22px 0 0;
}

.vital-birth-block .member-registration-block {
  margin-top: 5px;
  margin-bottom: 0;
}

.vital-add-baby-block .member-registration-block {
  clear: both;
  margin-bottom: 0;
}

.member-registration-block ul li {
  width: 215px;
  line-height: 20px !important;
}

.vital-add-baby-block li.vital-name input[type="text"] {
  margin-left: 0;
}

li.vital-name {
  > input {
    margin-right: 0.2rem;
  }
}

.buttonClear {
  background: #c7c7c7;
  background: -moz-linear-gradient(
    top,
    rgba(220, 220, 220, 1) 0%,
    rgba(172, 172, 172, 1) 97%,
    rgba(171, 171, 171, 0.5) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(220, 220, 220, 1)),
    color-stop(97%, rgba(172, 172, 172, 1)),
    color-stop(100%, rgba(171, 171, 171, 0.5))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(220, 220, 220, 1) 0%,
    rgba(172, 172, 172, 1) 97%,
    rgba(171, 171, 171, 0.5) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(220, 220, 220, 1) 0%,
    rgba(172, 172, 172, 1) 97%,
    rgba(171, 171, 171, 0.5) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(220, 220, 220, 1) 0%,
    rgba(172, 172, 172, 1) 97%,
    rgba(171, 171, 171, 0.5) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(220, 220, 220, 1) 0%,
    rgba(172, 172, 172, 1) 97%,
    rgba(171, 171, 171, 0.5) 100%
  );
  border: 0;
  font: bold 15px "Roboto-Regular";
  font-size: 15px;
  color: #333;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 0 30px;
  height: 32px;
  cursor: pointer;
  margin-right: 12px;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
  margin-bottom: 3px;
}

.buttonClear:hover,
.buttonSearch {
  background: #14a9db;
  background: -moz-linear-gradient(
    top,
    rgba(38, 200, 254, 1) 0%,
    rgba(2, 136, 181, 1) 97%,
    rgba(1, 134, 179, 0.5) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(38, 200, 254, 1)),
    color-stop(97%, rgba(2, 136, 181, 1)),
    color-stop(100%, rgba(1, 134, 179, 0.5))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(38, 200, 254, 1) 0%,
    rgba(2, 136, 181, 1) 97%,
    rgba(1, 134, 179, 0.5) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(38, 200, 254, 1) 0%,
    rgba(2, 136, 181, 1) 97%,
    rgba(1, 134, 179, 0.5) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(38, 200, 254, 1) 0%,
    rgba(2, 136, 181, 1) 97%,
    rgba(1, 134, 179, 0.5) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(38, 200, 254, 1) 0%,
    rgba(2, 136, 181, 1) 97%,
    rgba(1, 134, 179, 0.5) 100%
  );
  border: 0;
  font: bold 15px "Roboto-Regular";
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 0 30px;
  height: 32px;
  font-size: 15px;
  cursor: pointer;
  margin-right: 12px;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.8);
  margin-bottom: 3px;
}

.regdateeng {
  display: none;
}

input[type="text"][disabled] {
  background-color: #ebebe4;
}

/*! CSS Used from: https://public.donidcr.gov.np/Content/inettuts.css */
img,
h2,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
}

/*! CSS Used from: Embedded */
.Hospital {
  width: 510px !important;
}

/*! CSS Used from: https://public.donidcr.gov.np/Content/themes/base/jquery-ui.css */
.ui-helper-clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.ui-helper-clearfix {
  display: inline-block;
}

.ui-helper-clearfix {
  display: block;
}

.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-dialog {
  position: absolute;
  padding: 0.2em;
  width: 300px;
  overflow: hidden;
}

.ui-dialog .ui-dialog-titlebar {
  padding: 0.5em 1em 0.3em;
  position: relative;
}

.ui-dialog .ui-dialog-title {
  float: left;
  margin: 0.1em 16px 0.2em 0;
}

.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: 0.3em;
  top: 50%;
  width: 19px;
  margin: -10px 0 0 0;
  padding: 1px;
  height: 18px;
}

.ui-dialog .ui-dialog-titlebar-close span {
  display: block;
  margin: 1px;
}

.ui-dialog .ui-dialog-titlebar-close:hover,
.ui-dialog .ui-dialog-titlebar-close:focus {
  padding: 0;
}

.ui-dialog .ui-dialog-content {
  position: relative;
  border: 0;
  padding: 0.5em 1em;
  background: none;
  overflow: auto;
  zoom: 1;
}

.ui-dialog .ui-resizable-se {
  width: 14px;
  height: 14px;
  right: 3px;
  bottom: 3px;
}

.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}

.ui-resizable {
  position: relative;
}

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  z-index: 99999;
  display: block;
}

.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.ui-widget {
  font-size: 1em;
}

.ui-widget input {
  font-family: Verdana, Arial, sans-serif;
  font-size: 1em;
}

.ui-widget-content {
  background: #ffffff
    url(https://public.donidcr.gov.np/Content/themes/base/images/ui-bg_flat_75_ffffff_40x100.png)
    50% 50% repeat-x;
  color: #222222;
}

.ui-widget-content a {
  color: #222222;
}

.ui-widget-header {
  background: #cccccc
    url(https://public.donidcr.gov.np/Content/themes/base/images/mnu_lvl2.jpg)
    50% 50% repeat-x;
  background: url("https://public.donidcr.gov.np/Content/images/table-bg.png")
    repeat scroll 0 0 transparent;
  color: #222222;
  font-weight: bold;
}

.ui-widget-header a {
  color: #222222;
}

.ui-widget :active {
  outline: none;
}

.ui-icon {
  width: 16px;
  height: 16px;
  background-image: url(https://public.donidcr.gov.np/Content/themes/base/images/ui-icons_222222_256x240.png);
}

.ui-widget-content .ui-icon {
  background-image: url(https://public.donidcr.gov.np/Content/themes/base/images/ui-icons_222222_256x240.png);
}

.ui-widget-header .ui-icon {
  background-image: url(https://public.donidcr.gov.np/Content/themes/base/images/ui-icons_222222_256x240.png);
}

.ui-icon-closethick {
  background-position: -96px -128px;
}

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}

/*! CSS Used from: https://public.donidcr.gov.np/Content/css/style.css */
* {
  margin: 0;
  padding: 0;
}

div,
span,
a,
b,
table,
tbody,
thead,
tr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

th,
td {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #007fac;
}

a:hover {
  color: #333;
}

.table-block table {
  border: 1px solid #c0c1c6;
  text-align: left;
  margin-bottom: 10px;
  border-collapse: separate;
  border-right: 0;
  margin-left: auto;
  margin-right: auto;
}

.table-block table thead {
  background: url(https://public.donidcr.gov.np/Content/images/table-bg.png) 0 0
    repeat;
}

.table-block table thead {
  background: url(https://public.donidcr.gov.np/Content/images/table-bg.png) 0 0
    repeat;
  border: 1px solid #c0c1c6;
}

.table-block table th {
  font-family: "Roboto-Bold";
  color: #3f4450;
  font-size: 14px;
  line-height: 37px;
  padding: 0 10px;
  border-bottom: 2px solid #c0c1c6;
  box-shadow: inset 0px 2px 1px #fff;
  text-align: left;
  border-right: 1px solid #c0c1c6;
  overflow: hidden;
}

.table-block table th {
  font-family: "Roboto-Bold";
  color: #3f4450;
  font-size: 14px;
  line-height: 37px;
  padding: 5px 10px;
  border-bottom: 2px solid #c0c1c6;
  box-shadow: inset 0px 2px 1px #fff;
  text-align: left;
  border-right: 1px solid #c0c1c6;
}

.table-block table td {
  background: #fff;
  line-height: 40px;
  color: #3f4450;
  border-top: 1px solid #eee;
  border-right: 1px solid #c0c1c6;
  vertical-align: middle;
}

.table-block table tr.odd td {
  background: #f5f6fa;
}

input[type="text"] {
  background: #fff;
  border: 1px solid #ccc;
  float: left;
  font: 12px "Roboto-Regular";
  color: #333;
  padding: 3px 4px;
}

select {
  margin: 0;
  font: 12px "Roboto-Regular" !important;
  background: #fff;
  border: 1px solid #ccc;
  float: left;
  color: #333;
  padding: 3px 4px;
}

.table-block-new table {
  border: 1px solid #c0c1c6;
  text-align: left;
  margin-bottom: 10px;
  border-collapse: separate;
  border-right: 0;
  margin-left: auto;
  margin-right: auto;
}

.table-block-new table thead {
  background: url(https://public.donidcr.gov.np/Content/images/table-bg.png) 0 0
    repeat;
}

.table-block-new table thead {
  background: url(https://public.donidcr.gov.np/Content/images/table-bg.png) 0 0
    repeat;
  border: 1px solid #c0c1c6;
}

.table-block-new table th {
  font-family: "Roboto-Bold";
  color: #3f4450;
  font-size: 14px;
  line-height: 37px;
  border-bottom: 2px solid #c0c1c6;
  box-shadow: inset 0px 2px 1px #fff;
  text-align: left;
  border-right: 1px solid #c0c1c6;
  overflow: hidden;
}

.table-block-new table td {
  background: none !important;
  line-height: 40px;
  color: #3f4450;
  border-top: 1px solid #eee;
  border-right: 1px solid #c0c1c6;
  vertical-align: middle;
}

.table-block-new table tr.odd {
  background: #f5f6fa;
}

.table-block-new table tbody tr:hover {
  background: none repeat scroll 0 0 #c0c1c6 !important;
  cursor: pointer;
}

.table-block table td table {
  border: none;
  float: left;
}

.table-block table td table td {
  border: none;
  line-height: 15px;
}

/*! CSS Used from: Embedded */
.block-new table th {
  line-height: 30px;
  border: none;
  box-shadow: none;
}

.block-new table td {
  line-height: 27px;
  border-right: none;
}

// Custom modal

.custom-modal {
  .close {
    padding: 0;
    margin: 0;
  }
}

.break-line {
  height: 1px;
  width: 100%;
  background-color: #333;
  margin-top: 30px;
}

.new-page {
  margin-top: 50px;

  &::before {
    border-top: 2px dotted #000;
  }
}

// Settings
.icon-container {
  background: white;
  // margin: 0 auto;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
  padding: 30px 20px;
}

thead th {
  vertical-align: middle !important;
  font-weight: bold;
}

.settings-badge {
  right: 5%;
  top: 0;
  background-color: #6777ef;
  padding: 2px 10px;
  color: white;
  border-radius: 3px;
}

// .icon {
// 	h6 {
// 		// margin-top: 20px;
// 	}
// }

.card-footer-custom {
  background-color: #f5f6fa;
}

.brand_red {
  color: $red-shade !important;
}

.brand_primary {
  color: $primary !important;
}

.photobox {
  min-height: 1in;
  height: 1.5in;
  border: 2px solid black;
  font-size: 0.8rem;
  padding: 0.2rem;
  margin: 0 auto;
  margin: 0 10px !important;
}

.text-container {
  text-align: center;
}

.content-container {
  position: relative;
}

.tablephotocontainer {
  text-align: left;
  margin-bottom: 1rem;
  color: #000;
  padding: 0.5rem 1rem;

  table {
    border: none !important;

    td {
      border-style: hidden !important;
    }
  }
}

.nonEditable {
  color: red;
}

.A4 {
  .mb-5:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.post-signature {
  border-top: 1px dotted;
  padding-top: 0.5rem;
}

@media print {
  @page {
    margin: 5mm 5mm 10mm 5mm;
  }

  h3 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.5rem;
  }
}
