.error-message {
  color: #f44336;
  font-size: 0.75em;
}

.input-label-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  position: relative;
}

.input-label-container input {
  width: 100%;
  height: 38px;
  padding: 0px 14px;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  font-size: 16px;
}

.input-label-container input::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit;
}

.input-label-container input:focus {
  outline: 0;
  border: 1px solid #ccc;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-label-container input:not(:placeholder-shown) {
  outline: 0;
  border: 1px solid #ccc;
}

.input-label-container input:placeholder-shown + label {
  cursor: text;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transform-origin: -25px 0px;
  transform-origin: -25px 0px;
  color: #999999;
  -webkit-transform: translate(0, 28px) scale(1.4);
  transform: translate(0, 28px) scale(1.4);
}

.input-label-container input:focus::-webkit-input-placeholder {
  opacity: 1;
}

.input-label-container input:not(:placeholder-shown) + label {
  cursor: pointer;
  -webkit-transform: translate(15px, 13px);
  transform: translate(15px, 13px);
}

.input-label-container input:not(:placeholder-shown) + label::after {
  content: "";
  border-bottom: 1px solid #fff !important;
  width: 100%;
  left: 0px;
  top: 7px;
  z-index: -2000;
  position: absolute;
}

.input-label-container input:focus + label {
  cursor: pointer;
  color: #3f51b5;
  -webkit-transform: translate(15px, 13px);
  transform: translate(15px, 13px);
}

.input-label-container input:focus + label::after {
  content: "";
  border-bottom: 2px solid #fff !important;
  width: 100%;
  left: 0px;
  top: 7px;
  z-index: -2000;
  position: absolute;
}

.nc-container {
  float: left;
  position: absolute;
  z-index: 2;
  width: 360px;
  min-height: 350px;
  background: #fff;
  -webkit-box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  padding: 25px 30px;
}

.nc-container .nc-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nc-container .nc-head .nc-button {
  width: 45px;
  height: 100%;
  position: relative;
}

.nc-container .nc-head .nc-button .nc-inner {
  float: left;
  width: 35px;
  height: 35px;
  background: #f4f4f4;
  border-radius: 100%;
  text-align: center;
  position: absolute;
  margin-top: -17px;
  padding: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nc-container .nc-head .nc-button .nc-inner:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-head .nc-button .nc-inner:hover span {
  border-color: #555 !important;
}

.nc-container .nc-head .ndpch-container {
  float: left;
  width: 120px;
  height: 100%;
}

.nc-container .nc-head .ndpch-container .ndpchc-year {
  float: left;
  width: 100%;
  height: 30px;
  font-size: 27px;
  color: #666;
  font-weight: 200px;
  text-align: center;
}

.nc-container .nc-head .ndpch-container .ndpchc-month {
  float: left;
  width: 100%;
  height: 15px;
  font-size: 13px;
  color: #666;
  font-weight: 200px;
  text-align: center;
}

.nc-container .nc-body {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.nc-container .nc-body .nc-day-container {
  position: relative;
  display: block;
  float: left;
  width: 14.285%;
  height: 16.6666%;
}

.nc-container .nc-body .nc-day-container .cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .nc-day-container .cdc-day span {
  position: relative;
  display: block;
  float: left;
  color: #444;
  background: none;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  line-height: 30px;
}

.nc-container .nc-body .nc-day-container .cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-body .disabled {
  pointer-events: none;
}

.nc-container .nc-body .disabled .cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .disabled .cdc-day span {
  position: relative;
  display: block;
  float: left;
  color: #ddd;
  background: #fff !important;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  line-height: 30px;
}

.nc-container .nc-body .disabled .cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-body .highlight .cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .highlight .cdc-day span {
  position: relative;
  display: block;
  float: left;
  color: #444;
  background: #efdbca;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  line-height: 30px;
}

.nc-container .nc-body .highlight .cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-body .highlight-green .cdc-day {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #444;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .highlight-green .cdc-day span {
  position: relative;
  display: block;
  float: left;
  color: #444;
  background: #d4e2cb;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  line-height: 30px;
}

.nc-container .nc-body .highlight-green .cdc-day span:hover {
  cursor: pointer;
  background: #eee;
}

.nc-container .nc-body .inc-container {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  height: 100%;
}

.nc-container .nc-body .inc-container .cc-head {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .inc-container .cch-name {
  width: 14.285%;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  color: #666;
  font-size: 12px;
  text-align: center;
  position: relative;
  display: block;
  float: left;
}

.nc-container .nc-body .inc-container .cc-body {
  width: 100%;
  height: 270px;
  position: relative;
  display: block;
  float: left;
}
/*# sourceMappingURL=app.css.map */
