.profile-container {
	margin: 0 !important;
	width: 100%;
	.px-section {
		margin: 0 80px;
	}

	hr {
		border-top: 2px solid #333 !important;
	}

	.profile {
		&-card {
			width: 100%;
			box-sizing: border-box;
			padding: 1.5rem;
			background: white;
		}
		&-personal-info {
			padding: 1rem;
			border: 2px dashed #e9e9e9;
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}

		&-avatar {
			padding-top: 1.5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h4 {
				margin-block-start: 1rem;
				font-weight: 600;
			}
		}
		&-detail {
			display: flex;
			flex-direction: column;
			gap: 0.125rem;
			p {
				display: flex;
				align-items: center;
				gap: 0.375rem;
			}
		}

		.btn-outline-primary {
			color: $primary;
			border-color: $primary;
			width: 157px;
		}

		.btn-outline-primary:hover {
			color: $white;
		}
	}
	.recent-activities {
		background-color: white;
		padding: 1rem;
	}

	//pagination

	.page-link {
		background-color: none;
		border: none;
	}

	a.page-link.active-state {
		background-color: $primary;
		color: $white;
		border-radius: 3px;
		width: 30px;
		padding-bottom: 2px;
		padding-left: -2px;
		padding-right: 20px;
	}

	@media (max-width: 976px) {
		.dropdown {
			.switch-btn {
				background-color: $white;
				border-radius: 10px;
				width: 78px;
				height: 40px;

				.btn_inner {
					position: relative;
					top: -1px;
					left: -5px;
				}
			}

			.dropdown-menu {
				position: absolute;
				left: 4px;
				width: 250px;
				box-shadow: $box-shadow;
				border-radius: 4px;
				border: none;
				padding: 0;
			}
		}
	}
	.profile-page-info-icon {
		position: absolute;
		top: 0px;
		right: 10px;
		font-size: 25px;
	}
}

.profile-tab-container {
	// .nav-item {
	// 	padding: 0.5rem 1rem !important;
	// 	background: #ffffff;
	// 	margin-bottom: 0.5rem;
	// 	justify-content: flex-start;
	// 	.nav-link {
	// 		border: none;
	// 		color: $primary !important;
	// 	}
	// }
	.profile-tabs {
		background: white !important;
		margin-bottom: 1rem;
		padding: 0.25rem 0.5rem;
		.active {
			background: $primary;
			color: white;
			border-radius: 5px;
		}
	}
}
