.login-brand {
  margin: 20px 0;
  margin-bottom: 20px;
  color: #666;
  text-align: center;
  img {
    margin-bottom: 10px;
  }
  h3 {
    color: $red-shade;
  }
  p {
    color: $primary;
  }
}
.card.card-primary {
  border-top: 2px solid #6777ef;
}
.card .card-header h3 {
  font-size: 16px;
  line-height: 28px;
  color: #6777ef;
  padding-right: 10px;
  margin-bottom: 0;
}
.text-small {
  font-size: 12px;
  line-height: 20px;
}
.simple-footer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: grey;
    font-weight: bold;
  }
}
.form-group .control-label,
.form-group > label {
  font-weight: 600;
  color: #34395e;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.powered {
  height: 60px;
}
