.document-img-sm {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
}
.scroll {
  height: 70vh;
  overflow: auto;
}
.img-wrapper {
  margin-right: 20px;
  p {
    font-size: 12px;
  }
}
.document-scroll {
  height: 48vh;
  overflow: auto;
}
.document-img-lg {
  width: 100%;
}
