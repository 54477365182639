.spin {
  animation: spin 2s ease infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.height-75vh {
  height: 75vh;
}
.A4 {
  height: fit-content;
}

.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  &:focus-visible {
    outline-color: #dc3545 !important;
  }
}
.navbar__link--active {
  background: #efefef !important;
  font-weight: bold;
}